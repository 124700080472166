import React, { lazy, useEffect, useState, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axiosFetcher from "./components/AxiosFetcher/AxiosFetcher";
import "normalize.css";
// Pages
import { ErrorBoundary } from "react-error-boundary";
import { grid } from 'ldrs';

// Default values shown  

//Layouts
import LayoutWithFooter from "./components/layoutWithFooter/layoutWithFooter";
import Header from "./components/header/header";
import RedirectWrapper from "./components/redirectWrapper/redirectWrapper";
import Errors from "./components/Errors/Errors";
import NavigationHandler from "./components/NavigationHandler/NavigationHandler";


const PromoSelction = lazy(() => import("./Pages/PromoSelction"));
const FAQs = lazy(() => import("./Pages/FAQs"));
const AppliancesFAQs = lazy(() => import("./Pages/FAQ-NZ"));
const ContactUs = lazy(() => import("./Pages/Contactus"));
const ClaimSuccessful = lazy(() => import("./Pages/ClaimSuccessful"));
const PurchaseDetails = lazy(() => import("./Pages/PurchaseDetails"));
const PersonalDetails = lazy(() => import("./Pages/PersonalDetails"));
const UploadReceipt = lazy(() => import("./Pages/UploadReceipt"));
const Survey = lazy(() => import("./Pages/Survey/BoschSurvey"));
const VerifyUserDetails = lazy(() => import("./Pages/VerifyUserDetails"));
const UpdateSerialNumbers = lazy(() => import("./Pages/UpdateSerialNumbers"));
const GenericSplash = lazy(() => import("./Pages/GenericSplash"));
const DynamicFormPage = lazy(() => import("./Pages/DynamicFormPage"));
const BSHLaundryJune24PersonalDetails = lazy(() => import("./Pages/BSH/Laundry/June24/BSHLaundryJune24PersonalDetails"));
const BSHLaundryJune24PurchaseDetails = lazy(() => import("./Pages/BSH/Laundry/June24/BSHLaundryJune24PurchaseDetails"));
const BSHAppliancePersonalDetails = lazy(() => import("./Pages/BSH/Appliance/BSHAppliancePersonalDetails"));
const BSHAppliancePurchaseDetails = lazy(() => import("./Pages/BSH/Appliance/BSHAppliancePurchaseDetails"));

function App() {
    grid.register();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [clientDynamicContent, setClientDynamicContent] = useState({});

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const extractDomain = useMemo(() => (url) => {
        try {
            const hostname = new URL(url).hostname;
            const parts = hostname.split('.');

            // Handle localhost or single-part hostnames
            if (parts.length === 1) {
                return hostname;
            }
            // Handle common domain structures
            if (parts.length >= 3) {
                return parts.slice(-3, -2).join('.');
            } else if (parts.length === 2) {
                return parts[0];
            } else {
                return null; // or handle accordingly if it's not a valid domain structure
            }
        } catch (e) {
            console.error('Invalid URL:', url);
            return null; // or handle error as needed
        }
    }, []);

    useEffect(() => {
        const currentHLD = extractDomain(window.location.href);
        const storedDomain = sessionStorage.getItem('lastDomain');
        const storedContent = sessionStorage.getItem('clientDynamicContent');

        const loadGoogleAnalytics = () => {
            let gaTag;
            let clientName;
            const domain = currentHLD || window.location.hostname;
            console.log(`Current Domain: ${domain}`);
            if (domain.includes('.nz')|| domain.includes('boschhome')) {
                gaTag = 'G-EWWD4DTDFQ';  // GA4 Tag for ALL NZ Environments (DEV, UAT, PROD)
                clientName = "Bosch Promotions NZ";
            } else {
                gaTag = 'G-NSRKCKYHCC';  // GA4 Tag for ALL AU Environments (DEV, UAT, PROD)
                clientName = "Bosch Promotion AU";
            }

            if (gaTag) {
                console.log(`Loading GA Tag: ${gaTag} for ${clientName}`);

                // Ensure that dataLayer is defined before using it
                window.dataLayer = window.dataLayer || [];

                // Inject the GA script
                const script = document.createElement("script");
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTag}`;
                document.head.appendChild(script);

                // Initialize gtag after script is loaded
                script.onload = () => {
                    function gtag() { window.dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', gaTag);
                    console.log(`Google Analytics initialized for: ${clientName}`);
                };
            }
        };

            loadGoogleAnalytics(); // Load GA based on domain (NZ or AU)

        if (currentHLD !== storedDomain) {
            const fetchClientLevelDynamicContent = async () => {
                try {
                    setIsLoading(true);

                    const data = await axiosFetcher({
                        url: `/DynamicContent/content/ByClientDomain/${currentHLD}`,
                        method: 'GET',
                        enabled: true,
                    });

                    const client = await axiosFetcher({
                        url: `/Clients/byDomain/${currentHLD}`,
                        method: 'GET',
                        enabled: true,
                    });

                    const newDataObject = data.value.$values.reduce((acc, item) => {
                        acc[item.area] = item.contentData;
                        return acc;
                    }, {});

                    sessionStorage.setItem('clientDynamicContent', JSON.stringify(newDataObject));
                    sessionStorage.setItem('currentClient', JSON.stringify(client));
                    sessionStorage.setItem('lastDomain', currentHLD);
                    setClientDynamicContent(newDataObject);
                    setIsLoading(false);
                } catch (error) {
                    setIsError(true);
                    console.error('Fetching error:', error);
                    setIsLoading(false);
                }
            };

            fetchClientLevelDynamicContent();
        } else if (storedContent) {
            setClientDynamicContent(JSON.parse(storedContent));
            setIsLoading(false);
        }
    }, [extractDomain]);

    useEffect(() => {
        if (!isEmptyObject(clientDynamicContent)) {
            const html = document.documentElement;
            const isMobile = window.innerWidth < 768;

            const backgroundImageUrl = isMobile ? clientDynamicContent.Background_md : clientDynamicContent.Background_lg;

            if (!backgroundImageUrl) {
                console.warn('Background image URL is missing');
                return;
            }

            const img = new Image();
            img.src = backgroundImageUrl;
            img.onload = () => {
                html.style.backgroundImage = `url(${backgroundImageUrl})`;
                html.style.backgroundSize = 'cover';
                html.style.backgroundRepeat = 'no-repeat';
                html.style.backgroundColor = 'black';
            };

            img.onerror = () => {
                console.error('Failed to load background image:', backgroundImageUrl);
            };

            return () => {
                html.style.backgroundImage = '';
            };
        }
    }, [clientDynamicContent]);

    if (isLoading) {
        return (
            <>
                <div className="flex items-center justify-center h-screen">
                    <div className="text-center">
                        <l-grid id="loading-ring" size="150" speed="1.5" color="black"></l-grid>
                        <p>Loading...</p>
                    </div>
                </div>
            </>
        );
    }

    if (isError) {
        return <div>Error loading background image</div>;
    }

    return (
        <ErrorBoundary
            FallbackComponent={Errors}
            onError={() => console.log("Error")}
        >
            <Router>
                <Header />
                <NavigationHandler />
                <Routes>
                    <Route path="/DynamicForm" element={<DynamicFormPage />} />
                    <Route path="/" element={<PromoSelction />} />
                    <Route path="/PromoSelction" element={<RedirectWrapper><PromoSelction /></RedirectWrapper>} />
                    <Route path="/BSH/Laundry/PersonalDetails" element={<LayoutWithFooter><RedirectWrapper><BSHLaundryJune24PersonalDetails /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/BSH/Laundry/PurchaseDetails" element={<LayoutWithFooter><RedirectWrapper><BSHLaundryJune24PurchaseDetails /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/BSH/Appliance/PersonalDetails" element={<LayoutWithFooter><RedirectWrapper><BSHAppliancePersonalDetails /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/BSH/Appliance/PurchaseDetails" element={<LayoutWithFooter><RedirectWrapper><BSHAppliancePurchaseDetails /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/PurchaseDetails" element={<LayoutWithFooter><RedirectWrapper><PurchaseDetails /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/PersonalDetails" element={<LayoutWithFooter><RedirectWrapper><PersonalDetails /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/UploadReceipt" element={<LayoutWithFooter><RedirectWrapper><UploadReceipt /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/Splash" element={<LayoutWithFooter><RedirectWrapper><GenericSplash /></RedirectWrapper></LayoutWithFooter>} />
                    <Route path="/Survey" element={<Survey />} />
                    <Route path="/faqs" element={<LayoutWithFooter><FAQs /></LayoutWithFooter>} />
                    <Route path="/appliance-faqs" element={<LayoutWithFooter><AppliancesFAQs /></LayoutWithFooter>} />
                    <Route path="/Contactus" element={<LayoutWithFooter><ContactUs /></LayoutWithFooter>} />
                    <Route path="/VerifyUserDetails" element={<LayoutWithFooter><VerifyUserDetails /></LayoutWithFooter>} />
                    <Route path="/UpdateSerialNumbers" element={<LayoutWithFooter><UpdateSerialNumbers /></LayoutWithFooter>} />
                    <Route path="/ClaimSuccessful" element={<LayoutWithFooter><ClaimSuccessful /></LayoutWithFooter>} />
                    <Route path="*" element={<PromoSelction />} />
                </Routes>
            </Router>
         </ErrorBoundary>
    );
}

export default App;
